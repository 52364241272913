/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import AnimatedNumber from "react-animated-number";
import { useIcons } from "../../assets/hooks/useIcons";
// import { truncateAddress } from "../../assets/utilis/utilis";
import {
  BURGER_BTN_ICON,
  DISCONNECT_ICON,
  ARROW_ICON,
  // ARROW_ICON,
  // DISCONNECT_ICON,
} from "../../assets/utilis/icons";
import "./Header.css";
import { prettyValue, truncateAddress } from "../../assets/utilis/utilis";
import { ethers } from "ethers";

function Header({
  isLoggedIn,
  isMenuOpen,
  toggleMenu,
  onModalOpen,
  disconnect,
  user,
  isGamePlaying,
  tokenDecimals,
  sendToIframe,
  active,
  account,
  chainId
}) {
  const overlay = useRef();
  const { setCurrencyIconByChainId, setCurrencyTitleByChainId } = useIcons();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current !== evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    if (user && user.balance !== undefined) setBalance(user.balance);
  }, [user]);

  function prettyBalanceValue(n, title) {
    n = n ? ethers.utils.parseUnits(n.toFixed(tokenDecimals), tokenDecimals) : ethers.BigNumber.from(0)
    return prettyValue(n, tokenDecimals) + ` ${title}`
  }

  function logout() {
    disconnect();
    setPopupOpen(false);
    sendToIframe("editData", { set: { "isExternalWalletConnected": false } })
  }

  return (
    // <header className="header">
    //   <div className="header__container">
    //     <button
    //       className="header__burger-button"
    //       type="button"
    //       aria-label="Open menu"
    //       onClick={!isMenuOpen ? toggleMenu : undefined}
    //     >
    //       {BURGER_BTN_ICON}
    //     </button>
    //   </div>
    // </header>
    <header className="header">
      <div className="header__container">
        <button
          className="header__burger-button"
          type="button"
          aria-label="Open menu"
          onClick={!isMenuOpen ? toggleMenu : undefined}
        >
          {BURGER_BTN_ICON}
        </button>
        <div></div>

        <div className={`header__info-block ${isGamePlaying ? 'header__info-block_disabled' : ''}`}>
          <div
            className={`header__demo-block ${user && active ? "header__demo-block_user" : ""
              }`}
          >
            {user && active && isLoggedIn ?
              <div className="header__demo-box">
                <img
                  className="header__currency-icon"
                  src={setCurrencyIconByChainId(chainId)}
                  alt="Network icon"
                />
                <AnimatedNumber
                  component="p"
                  value={Number(ethers.utils.formatUnits(balance, tokenDecimals))}
                  className="header__demo-text"
                  duration={200}
                  formatValue={(n) => prettyBalanceValue(n, setCurrencyTitleByChainId(chainId))}
                />
              </div>
              : null
            }
          </div>
          {!active || !isLoggedIn ? (
            <button
              className={`header__login-btn `}
              type="button"
              onClick={onModalOpen}
            >
              Connect
            </button>
          ) :
            <div className="header__box-with-drop">
              <button
                className="header__user-box"
                type="button"
                onClick={() => setPopupOpen(true)}
              >
                <p className="header__user-text">
                  {account ? truncateAddress(account) : null}
                </p>
                <div
                  className={`header__arrow-icon ${isPopupOpen ? "header__arrow-icon_reverse" : ""
                    }`}
                >
                  {ARROW_ICON}
                </div>
              </button>

              <div
                className={`header__logout-popup ${isPopupOpen ? "header__logout-popup_opened" : ""
                  }`}
                ref={overlay}
              >
                <button
                  className="header__logout-btn"
                  type="button"
                  onClick={logout}
                >
                  <p className="header__logout-text">Disconnect</p>
                  <div className="header__logout-icon">{DISCONNECT_ICON}</div>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
