import { useEffect } from "react";
import moment from "moment-timezone";

import { useIcons } from "../../assets/hooks/useIcons";
import {
  // LIVE_TABLE,
  // MY_TABLE
} from "../../assets/utilis/constants";
import demo from "../../assets/images/currency/jellybet-currency.png";
import heads from "../../assets/images/coin-flip/heads-icon.png";
import tails from "../../assets/images/coin-flip/tails-icon.png";

import "./GamesTable.css";
import mainApi from "../../assets/api/MainApi";
import { prettyValue } from "../../assets/utilis/utilis";

function GamesTable({
  // allGames,
  // gamesByUser,
  gameType,
  games,
  setGames,
  tokenDecimals
}) {
  const { setCurrencyIconByChainId } = useIcons();
  // const [table, setTable] = useState(LIVE_TABLE);
  // const [data, setData] = useState([]);
  const icons = { heads: heads, tails: tails };

  // useEffect(() => {
  //   table === LIVE_TABLE ? setData(allGames) : setData(gamesByUser);
  // }, [allGames, gamesByUser, table]);

  // useEffect(() => {
  //   if (!account) {
  //     setTable(LIVE_TABLE);
  //     setData(allGames);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [account]);

  // function handleTable() {
  //   switch (table) {
  //     case LIVE_TABLE:
  //       setTable(MY_TABLE);
  //       setData(gamesByUser);
  //       break;
  //     case MY_TABLE:
  //       setTable(LIVE_TABLE);
  //       setData(allGames);
  //       break;
  //     default: {
  //       setTable("");
  //       setData([]);
  //     }
  //   }
  // }

  function valueFormat(value) {
    const val = ("0000" + value.toString()).slice(-4);
    const integrer = val.slice(0, 2);
    const decimal = val.slice(-2);
    return `${integrer}.${decimal}`;
  }

  useEffect(() => {
    mainApi.getAllGames({ limit: 25, gameTypes: gameType })
      .then((res) => {
        // console.log(res)
        const games = res.data.map(
          (game) =>
          (game = {
            ...game,
            sent_value: prettyValue(
              Number(Number(game.sent_value).toFixed(0)).toString(), tokenDecimals
            ),
            payout_amount: prettyValue(
              Number(Number(game.payout_amount).toFixed(0)).toString(), tokenDecimals
            ),
          })
        );
        // console.log("all game sessions:", games);
        setGames(games)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [tokenDecimals])

  return (
    <div className="tables">

      <div className="tables__title-block">
        {/* <button
          className={`tables__title-container ${table === LIVE_TABLE ? "tables__title-container_selected" : ""
            }`}
          type="button"
          onClick={handleTable}
        > */}
        <button
          className={`tables__title-container tables__title-container_selected`}
          type="button"
        >
          <p className="tables__title">Live Games</p>
          <div className="tables__title-border" />
        </button>
        {/* {user && active ? (
          <button
            className={`tables__title-container ${table === MY_TABLE ? "tables__title-container_selected" : ""
              }`}
            type="button"
            onClick={handleTable}
          >
            <p className="tables__title">My Games</p>
            <div className="tables__title-border" />
          </button>
        ) : null} */}
      </div>

      <div className="tables__table-container">
        <table className="tables__table">
          <thead className="tables__table-head">
            <tr className="tables__table-tr-head">
              <th
                className="tables__table-th-head tables__table-th-head_type_time"
                colSpan="1"
              >
                <p className="tables__table-title">Time</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_player"
                colSpan="1"
              >
                <p className="tables__table-title">Player</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_transaction"
                colSpan="1"
              >
                <p className="tables__table-title">Transaction</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_bet"
                colSpan="1"
              >
                <p className="tables__table-title">Bet</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_result"
                colSpan="1"
              >
                <p className="tables__table-title">Result</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_bet-size"
                colSpan="1"
              >
                <p className="tables__table-title">Bet size</p>
              </th>
              <th
                className="tables__table-th-head tables__table-th-head_type_payout"
                colSpan="1"
              >
                <p className="tables__table-title">Payout</p>
              </th>
            </tr>
          </thead>

          <tbody className="tables__table-body">
            {games.length > 0 ? (
              games.map((game, i) => (
                <tr
                  className={`tables__table-tr-body`}
                  key={`tables__table-body-tr${i}`}
                >
                  <td className="tables__table-td-body tables__table-td-body_type_time">
                    <div className="tables__table-cell">
                      {game.utc_date ? (
                        <p
                          className="tables__table-text"
                          title={moment(
                            game.utc_date + "+00:00",
                            "YYYY-MM-DDTHH:mm:ssZ"
                          ).format("DD.MM.YYYY HH:mm:ss")}
                        >
                          {moment(
                            game.utc_date + "+00:00",
                            "YYYY-MM-DDTHH:mm:ssZ"
                          ).fromNow()}
                        </p>
                      ) : null}
                    </div>
                  </td>

                  <td
                    className="tables__table-td-body tables__table-td-body_type_player"
                    title={game.address}
                  >
                    {!game.is_demo ? (
                      <a
                        className="tables__id-text"
                        href={`https://arbiscan.io/address/${game.address}`}
                        target="_blank"
                        rel="noreferrer"
                      // onClick={() => { copyText(game.address) }}
                      >
                        {game.address.length > 10
                          ? `${game.address.slice(0, 4)}...${game.address.slice(
                            -6
                          )}`
                          : game.address}
                      </a>
                    ) : (
                      <p className="tables__id-text">
                        {game.address.length > 10
                          ? `${game.address.slice(0, 4)}...${game.address.slice(
                            -6
                          )}`
                          : game.address}
                      </p>
                    )}
                  </td>

                  <td
                    className="tables__table-td-body tables__table-td-body_type_transaction"
                    title={game.transaction_hash}
                  >
                    {!game.is_demo ? (
                      <a
                        className="tables__id-text"
                        href={`https://arbiscan.io/tx/${game.transaction_hash}`}
                        target="_blank"
                        rel="noreferrer"
                      // onClick={() => { copyText(game.transaction_hash) }}
                      >
                        {game.transaction_hash.length > 10
                          ? `${game.transaction_hash.slice(
                            0,
                            4
                          )}...${game.transaction_hash.slice(-6)}`
                          : game.transaction_hash}
                      </a>
                    ) : (
                      <p className="tables__id-text">
                        {game.transaction_hash.length > 10
                          ? `${game.transaction_hash.slice(
                            0,
                            4
                          )}...${game.transaction_hash.slice(-6)}`
                          : game.transaction_hash}
                      </p>
                    )}
                  </td>

                  <td className="tables__table-td-body tables__table-td-body_type_bet">
                    {game.game_type === "megadice" ? (
                      <div className="tables__table-cell">
                        <p className="tables__table-text tables__table-text_type_range">
                          {game.up ? ">" : "<"}
                        </p>
                        <p className="tables__table-text">
                          {valueFormat(game.position)}
                        </p>
                      </div>
                    ) : game.game_type === "coinflip" ? (
                      <div className="tables__table-cell">
                        {game.winning_side ? (
                          <img
                            className="tables__coin-icon"
                            src={icons.heads}
                            alt=""
                          />
                        ) : (
                          <img
                            className="tables__coin-icon"
                            src={icons.tails}
                            alt=""
                          />
                        )}
                        <p className="tables__table-text">
                          x{game.coins_to_win}/{game.coins_total}
                        </p>
                      </div>
                    ) :
                      game.game_type === "limbo" ?
                        <div className="tables__table-cell">
                          <p className="tables__table-text">
                            x{Number(game.target) / 100}
                          </p>
                        </div>
                        :
                        null
                    }
                  </td>

                  <td className="tables__table-td-body tables__table-td-body_type_result">
                    {game.game_type === "megadice" ? (
                      <div className="tables__table-cell">
                        <p className="tables__table-text">
                          {game.is_demo
                            ? game.random_value
                            : valueFormat(game.random_value)}
                        </p>
                      </div>
                    ) : game.game_type === "coinflip" ? (
                      <div className="tables__table-cell">
                        {game.winning_side ? (
                          <img
                            className="tables__coin-icon"
                            src={icons.heads}
                            alt=""
                          />
                        ) : (
                          <img
                            className="tables__coin-icon"
                            src={icons.tails}
                            alt=""
                          />
                        )}
                        <p className="tables__table-text">
                          x{game.random_value}/{game.coins_total}
                        </p>
                      </div>
                    ) :
                      ["limbo"].includes(game.game_type) ?
                        <div className="tables__table-cell">
                          <p className="tables__table-text">
                            x{Number(game.random_value) / 100}
                          </p>
                        </div>
                        :
                        null
                    }
                  </td>

                  <td className="tables__table-td-body tables__table-td-body_type_bet-size">
                    <div className="tables__table-cell tables__table-cell_type_bet-size">
                      <p className="tables__money-text">
                        {game.sent_value}
                      </p>
                      <img
                        className="tables__currency-icon"
                        src={
                          game.is_demo ? demo : setCurrencyIconByChainId(game.network)
                        }
                        alt={`${game.network} network icon`}
                      />
                    </div>
                  </td>

                  <td className="tables__table-td-body tables__table-td-body_type_payout">
                    <div className="tables__table-cell tables__table-cell_type_payout">
                      <p
                        className={`tables__money-text ${Number(game.payout_amount) !== 0
                          ? "tables__money-text_type_green"
                          : ""
                          }`}
                      >
                        {game.payout_amount}
                      </p>
                      <img
                        className="tables__currency-icon"
                        src={
                          game.is_demo ? demo : setCurrencyIconByChainId(game.network)
                        }
                        alt={`${game.network} network icon`}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tables__table-tr-body tables__table-tr-body_type_not-found">
                <td className="tables__not-found" colSpan="7">
                  Nothing was found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GamesTable;
