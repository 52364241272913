import { 
  MAIN_URL,
  // ARBITRUM_NETWORK, 
  POLYGON_MUMBAI_NETWORK 
} from "../utilis/constants";

class MainApi {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  getAllGames({limit, gameTypes}) {
    let params = {
      "network": POLYGON_MUMBAI_NETWORK
    };
    if (limit) params.limit = limit;
    if (gameTypes) params.game_types = gameTypes;

    return fetch(
      `${MAIN_URL}/transactions/all-game-sessions?` +
      new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;