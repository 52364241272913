import {
  COIN_FLIP_GAME,
  MEGA_DICE_GAME,
  LIMBO_GAME,
  USDT_CURRENCY,
} from "../utilis/constants";
import {
  COIN_FLIP_ICON,
  MEGA_DICE_ICON,
  LIMBO_ICON,
} from "../utilis/icons";
import usdt from "../images/currency/tether-usdt-logo.png"

export function useIcons() {


  function setGameIcon(game) {
    switch (game) {
      case COIN_FLIP_GAME:
        return COIN_FLIP_ICON;
      case MEGA_DICE_GAME:
        return MEGA_DICE_ICON;
      case LIMBO_GAME:
        return LIMBO_ICON;
      default:
        return null;
    }
  }

  function setGameTitle(game) {
    switch (game) {
      case COIN_FLIP_GAME:
        return "Coin Flip";
      case MEGA_DICE_GAME:
        return "Mega Dice";
      case LIMBO_GAME:
        return "Limbo";
      default:
        return "Demo";
    }
  }

  function setCurrencyTitleByChainId(chain) {
    switch (chain) {
      // case Arbitrum.chainId:
      //   return USDT_CURRENCY; /// not right decission
      // case Polygon.chainId:
      //   return USDT_CURRENCY; /// not right decission
      // case Mumbai.chainId:
      //   return USDT_CURRENCY; /// not right decission
      default:
        return USDT_CURRENCY;
    }
  }

  function setCurrencyIconByChainId(chain) {
    switch (chain) {
      // case Arbitrum.chainId:
      //   return usdt; /// not right decission
      // case Polygon.chainId:
      //   return usdt; /// not right decission
      // case Mumbai.chainId:
      //   return usdt; /// not right decission
      default:
        return usdt;
    }
  }

  return {
    setGameIcon,
    setGameTitle,
    setCurrencyIconByChainId,
    setCurrencyTitleByChainId,
  };
}
