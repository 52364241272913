import Banner from "./Banner/Banner";
import Games from "./Games/Games";
import LiveGames from "./LiveGames/LiveGames";

import "./Main.css";

function Main({  account, wheelGameTime }) {
  return (
    <main className="main">
      <Banner />
      <Games />
      <LiveGames {...{account }}/>
    </main>
  );
}

export default Main;
