/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import {
  useTokenBalance,
  useEthers,
  useConfig,
  useSendTransaction
} from "@usedapp/core";
import Web3 from 'web3';
import { Magic } from 'magic-sdk';
import useWindowSize from "../../assets/hooks/useWindowSize";

import { ethers } from "ethers";

import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Main from "../Main/Main";
import MegaDice from "../MegaDice/MegaDice";
import CoinFlip from "../CoinFlip/CoinFlip";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Limbo from "../Limbo/Limbo";
import WalletModal from "../WalletModal/WalletModal";

import { DEFAULT_CHAIN_ID, DEFAULT_USDT_ADDRESS, DEMO_DECIMALS, GAMES_URL, IERC20_METADATA_ABI } from "../../assets/utilis/constants";

const PUBLIC_MAGIC_API_KEY = "pk_live_BF55977681E5B785";

function App() {
  const {
    account,
    active,
    chainId,
    library,
    switchNetwork,
    activateBrowserWallet,
    deactivate,
  } = useEthers();
  const { pathname } = useLocation();

  const { readOnlyUrls } = useConfig();

  const [isGamePlaying, setGamePlaying] = useState(false);
  const [isSupportedNetwork, setSupportedNetwork] = useState(false);
  const [preferredChainId,] = useState(DEFAULT_CHAIN_ID);
  const [tokenAddress,] = useState(DEFAULT_USDT_ADDRESS);
  const [tokenDecimals, setTokenDecimals] = useState(DEMO_DECIMALS);
  const [user, setUser] = useState(undefined);

  const [activeAccountStateVar, setActiveAccountStateVar] = useState(undefined);
  const [isActive, setIsActive] = useState(false);
  const [isMagicConnectorStateVar, setIsMagicConnectorStateVar] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const overlay = useRef();

  const [isWalletModalOpen, setWalletModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isEventListenerActivated, setEventListenerActivated] = useState(false);
  const [isLibraryEventListenerActivated, setLibraryEventListenerActivated] = useState(false);
  const [isMagicEventListenerActivated, setMagicEventListenerActivated] = useState(false);

  const balance = useTokenBalance(tokenAddress, activeAccountStateVar, {
    refresh: "everyBlock",
    chainId: preferredChainId
  });

  const magic = new Magic(PUBLIC_MAGIC_API_KEY, {
    network: {
      rpcUrl: "https://rpc.ankr.com/polygon_mumbai",
      chainId: preferredChainId,
    }
  });

  const sendTxFunction = useSendTransaction();
  const txState = sendTxFunction.state;
  const sendTx = sendTxFunction.sendTransaction;
  const [txPayload, setTxPayload] = useState(undefined);
  const [gameHistory, setGameHistory] = useState([]);
  const [checkGameHistory, setCheckGameHistory] = useState(undefined);
  const [isFrameLoaded, setFrameLoaded] = useState(false);
  const [sendToFrameState, setSendToFrameState] = useState([]);
  const [sendToFrameOffset, setSendToFrameOffset] = useState(0);

  useEffect(() => {
    if (!isLibraryEventListenerActivated && library) {
      window.addEventListener('message', handleMessageLibrary);
      setLibraryEventListenerActivated(true);
    }
  }, [library])

  useEffect(() => {
    if (!isMagicEventListenerActivated && magic) {
      window.addEventListener('message', handleMessageMagic);
      setMagicEventListenerActivated(true);
    }
  }, [magic])

  useEffect(() => {
    if (!isEventListenerActivated) {
      window.addEventListener('message', handleMessage);
      setEventListenerActivated(true);
    }
  }, [isEventListenerActivated])

  function isMagicConnector() {
    return localStorage.getItem('magic:is_authenticated') === 'true' ? true : false;
  }

  function setIsMagicConnector(value) {
    if (!value) {
      localStorage.removeItem('magic:is_authenticated');
    } else {
      localStorage.setItem('magic:is_authenticated', value);
    }
  }

  function activeAccount() {
    return localStorage.getItem('active_account') ?? '';
  }

  function setActiveAccount(value) {
    if (!value) {
      localStorage.removeItem('active_account');
    } else {
      localStorage.setItem('active_account', value);
    }
  }

  useEffect(() => {
    if (!isPageLoaded || isMagicConnectorStateVar) return;
    setActiveAccountStateVar(account);
  }, [isMagicConnectorStateVar, account])

  useEffect(() => {
    if (!isPageLoaded) return;
    setIsActive(active);
  }, [active])

  useEffect(() => {
    if (!isPageLoaded) return;
    setIsMagicConnector(isMagicConnectorStateVar)
  }, [isMagicConnectorStateVar])

  useEffect(() => {
    if (!isPageLoaded) return;
    setActiveAccount(activeAccountStateVar)
  }, [activeAccountStateVar])

  useEffect(() => {
    setIsPageLoaded(true);
    if (isMagicConnector()) {
      setMagicAccount();
    }
  }, [])

  const setMagicAccount = async () => {
    const accounts = await magic.wallet.connectWithUI();
    if (accounts) {
      setActiveAccountStateVar(accounts[0]);
      setIsActive(true);
      setIsMagicConnectorStateVar(true);
      setSupportedNetwork(true);
    }
  }

  const activateBrowserWalletMain = async (type, isMagic) => {
    if (!isMagic) {
      activateBrowserWallet({ type });
    } else {
      try {
        await setMagicAccount();
      } catch (err) {
        console.log(err)
      }
    }
  }

  function handleMessageLibrary(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = GAMES_URL;

    if (event.origin === parser.origin) {
      let receivedData = JSON.parse(event.data);
      if (receivedData.type === "signMessage") {
        /// handling request to sign a message
        signAuthMessage(receivedData.payload.message)
          .then(
            (res) => {
              sendToIframe("signMessage", { ...receivedData.payload, signature: res })
            }
          )
          .catch((err) => console.log("sign auth message err:", err));
      } else if (receivedData.type === "sendTx") {
        /// handling request to send tx
        setTxPayload(receivedData.payload)
      } else if (receivedData.type === "switchNetwork") {
        /// handling request to change chainId
        switchNetwork(receivedData.payload.chainId)
      }
      console.log({ data: JSON.parse(event.data) })
    }
  }

  function handleMessageMagic(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = GAMES_URL;

    if (!isMagicConnector) return;

    if (event.origin === parser.origin) {
      let receivedData = JSON.parse(event.data);
      if (receivedData.type === "signMessage") {
        /// handling request to sign a message
        signAuthMessage(receivedData.payload.message)
          .then(
            (res) => {
              sendToIframe("signMessage", { ...receivedData.payload, signature: res })
            }
          )
          .catch((err) => console.log("sign auth message err:", err));
      } else if (receivedData.type === "sendTx") {
        /// handling request to send tx
        setTxPayload(receivedData.payload)
      } else if (receivedData.type === "switchNetwork") {
        /// handling request to change chainId
        switchNetwork(receivedData.payload.chainId)
      }
      console.log({ data: JSON.parse(event.data) })
    }
  }

  function handleMessage(event) {
    event.stopPropagation();

    let parser = document.createElement('a');
    parser.href = GAMES_URL;

    if (event.origin === parser.origin) {
      let receivedData = JSON.parse(event.data);
      if (receivedData.type === "setGamePlaying") {
        /// handling request to setGamePlaying
        setGamePlaying(receivedData.payload.value)
      } else if (receivedData.type === "newGameHistory") {
        /// handling request to add game to history
        setCheckGameHistory(receivedData.payload);
      } else if (receivedData.type === "requestToWalletConnect") {
        /// handling request to connect wallet btn
        if (!user) {
          openWalletModal();
        } else {
          sendToIframe("editData", { set: { isExternalWalletConnected: true } })
        }
      }
      console.log({ data: JSON.parse(event.data) })
    }
  }

  useEffect(() => {
    if (!checkGameHistory) return;
    if (
      (['luckyWheel'].includes(checkGameHistory.game_type) && ['/lucky-wheel'].includes(pathname)) ||
      (['coinflip'].includes(checkGameHistory.game_type) && ['/lucky-sevens', '/coin-flip'].includes(pathname)) ||
      (['megadice'].includes(checkGameHistory.game_type) && ['/mega-dice'].includes(pathname)) ||
      (['limbo'].includes(checkGameHistory.game_type) && ['/limbo', '/shuttle', '/crash'].includes(pathname))
    ) setGameHistory((prevArr) => [checkGameHistory, ...prevArr]);
  }, [checkGameHistory])

  const sendMagicTx = async(txPayload) => {
    sendToIframe("updateTxStatus", { status: "Pending signature" });
    const provider = new ethers.providers.Web3Provider(magic.rpcProvider);
    const signer = provider.getSigner();

    // Submit transaction to the blockchain
    await signer
      .sendTransaction(txPayload)
      .then((tx) => {
        sendToIframe("updateTxStatus", { status: "Mining" });
        provider.once(tx.hash, (receipt) => {
          sendToIframe("updateTxStatus", { status: "Success" });
        });
      })
      .catch((err) => {
        sendToIframe("updateTxStatus", { status: "Fail" });
      });
  }

  useEffect(() => {
    if (!txPayload) return;
    if (!isMagicConnectorStateVar) {
      sendTx(txPayload)
    } else {
      sendMagicTx(txPayload)
    }
  }, [txPayload])

  function sendToIframe(eventType, eventPayload) {
    setSendToFrameState((prevNotifications) => {
      return [...prevNotifications, { eventType, eventPayload }];
    });
  }

  useEffect(() => {
    if (isFrameLoaded && sendToFrameState) {
      for (var i = sendToFrameOffset; i < sendToFrameState.length; i++) {
        setSendToFrameOffset(i + 1);
        sendToIframeMain(sendToFrameState[i].eventType, sendToFrameState[i].eventPayload);
      }
    }
  }, [sendToFrameState])

  useEffect(() => {
    if (isFrameLoaded) {
      /// check chain
      sendToIframeMain("editData", { set: { isSupportedNetwork: isSupportedNetwork } });
      /// check account
      if (activeAccountStateVar) sendToIframeMain("editData", { set: { externalAccount: activeAccountStateVar } });
      /// check isConnected
      /// check chain
      /// check account
      if (activeAccountStateVar) sendToIframeMain("editData", { set: { isExternalWalletConnected: isActive, isSupportedNetwork: isSupportedNetwork, externalAccount: activeAccountStateVar } })
      else sendToIframeMain("editData", { set: { isExternalWalletConnected: isActive, isSupportedNetwork: isSupportedNetwork } });
    }
  }, [isFrameLoaded])

  function sendToIframeMain(eventType, eventPayload) {
    let iframe = document.getElementsByClassName("app__iframe");
    if (iframe.length !== 0) {
      iframe[0].contentWindow.postMessage(JSON.stringify({ type: eventType, payload: eventPayload }), GAMES_URL)
    } else {
      console.log('iframe err: page not found')
    }
  }

  useEffect(() => {
    if (isMagicConnectorStateVar) return;
    sendToIframe("updateTxStatus", { status: txState.status })
  }, [txState])

  // close side bar on overlay click
  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    if (activeAccountStateVar && isActive) {
      setUser({ activeAccountStateVar, balance: ethers.BigNumber.from(0) });
      sendToIframe("editData", { set: { externalAccount: activeAccountStateVar, isExternalWalletConnected: true } })
    }
    else if (!activeAccountStateVar) {
      setUser(undefined);
      sendToIframe("editData", { set: { isExternalWalletConnected: false } })
    };
  }, [isActive, activeAccountStateVar]);

  const updateDecimals = async (tokenAddress) => {
    const signer = new ethers.providers.JsonRpcProvider(readOnlyUrls[preferredChainId]);
    const ctr = new ethers.Contract(tokenAddress, IERC20_METADATA_ABI, signer);
    setTokenDecimals(await ctr.decimals());
  };

  // track network change
  useEffect(() => {
    if (isMagicConnector()) return;
    setSupportedNetwork(chainId === preferredChainId);
  }, [chainId]);

  useEffect(() => {
    if (!isSupportedNetwork) {
      sendToIframe("editData", { set: { isSupportedNetwork: false } })
    } else {
      sendToIframe("editData", { set: { isSupportedNetwork: true } })
    }
  }, [isSupportedNetwork])

  // track network change
  useEffect(() => {
    updateDecimals(tokenAddress);
  }, [tokenAddress]);

  // listen for balance changes
  useEffect(() => {
    if (balance === undefined || !user) return;

    const newBalance = balance;
    if (isGamePlaying) {
      if (newBalance.lt(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    } else {
      if (!newBalance.eq(user.balance))
        setUser((prevUser) => ({ ...prevUser, balance: newBalance }));
    }

  }, [balance, isGamePlaying]);

  const signAuthMessage = async (message) => {
    let signature;
    if (!isMagicConnector() && !library) {
      return;
    } else if (!isMagicConnector()) {
      console.log(library.getSigner(), message)
      signature = library.getSigner().signMessage(message);
    } else {
      const provider = await magic.wallet.getProvider();
      const magicWeb3 = new Web3(provider);
      signature = await magicWeb3.eth.personal.sign(
        message,
        activeAccount(),
        ""
      );
    }
    return signature;
  };

  // logout

  const disconnect = async () => {
    if (!isMagicConnectorStateVar) {
      deactivate();
    } else {
      await magic.wallet.disconnect();
      setIsMagicConnectorStateVar(false);
    }
    setActiveAccountStateVar(undefined);
    setIsActive(false);
    setUser(undefined);
  };

  // toggle side bar state
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > 1150) setMenuOpen(false);
  }, [width]);

  // toggle side bar state
  function toggleMenu() {
    setMenuOpen(!isMenuOpen);
  }

  // toggle wallet modal visibility state
  function openWalletModal() {
    setWalletModalOpen(true);
  }

  function closeWalletModal() {
    setWalletModalOpen(false);
  }

  return (
    <>
      <div className="app">
        <SideBar
          chainId={chainId}
          {...{
            isMenuOpen,
            toggleMenu,
            tokenDecimals,
            user
          }}
        />

        <div className="app__content">
          <Header
            onModalOpen={openWalletModal}
            isLoggedIn={!!activeAccountStateVar}
            active={isActive}
            account={activeAccountStateVar}
            chainId={chainId}
            {...{
              isMenuOpen,
              toggleMenu,
              disconnect,
              user,
              isGamePlaying,
              tokenDecimals,
              sendToIframe
            }}
          />

          <Routes>
            <Route
              exact
              path="/"
              element={
                <Main
                />
              }
            />
            <Route
              path="/mega-dice"
              element={
                <MegaDice
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/coin-flip"
              element={
                <CoinFlip
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/limbo"
              element={
                <Limbo
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            {/* <Route
              path="/crash"
              element={
                <Crash
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/lucky-sevens"
              element={
                <LuckySevens
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/shuttle"
              element={
                <Shuttle
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            />
            <Route
              path="/lucky-wheel"
              element={
                <LuckyWheel
                  chainId={preferredChainId}
                  {...{
                    gameHistory,
                    setGameHistory,
                    tokenDecimals,
                    setFrameLoaded
                  }}
                />
              }
            /> */}
          </Routes>

          <WalletModal
            isOpen={isWalletModalOpen}
            onClose={closeWalletModal}
            activateBrowserWallet={activateBrowserWalletMain}
          />

          <div
            className={`app__overlay ${isMenuOpen ? "app__overlay_opened" : ""
              }`}
            ref={overlay}
            onTouchStart={() => setMenuOpen(false)}
          />
        </div>
      </div>
    </>
  );
}

export default App;