import jellybet from "../../../assets/images/main/jelly-bet-banner.png";
import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <div className="banner__title-box">
        <h1 className="banner__title">
          Decentralized
          <br />
          <span className="banner__title_type_gradient">Casino</span>
          <br />
          gaming platform
        </h1>
        <p className="banner__subtitle">
          Your favourite games brought on chain
          <br />
          by <span className="banner__subtitle_type_return">888Alien</span> & <a className="banner__subtitle_type_return" href="https://paraliq.com/" target="_blank" rel="noreferrer">Paraliq</a>
        </p>
      </div>

      <img className="banner__image" src={jellybet} alt="Pink jellyfish" />
      <div className="banner__back-ellipse" />
    </div>
  );
}

export default Banner;
