//games pictures
import coinFlip from "../images/main/coin-flip-game.png";
import megaDice from "../images/main/mega-dice-game.png";
import plinko from "../images/main/plinko-game-grey.png";
import limbo from "../images/main/limbo-game.jpeg";

//wallets icons
import walletConnect from "../images/currency/wallet-connect.png";
import metamask from "../images/currency/metamask.png";
import coinbase from "../images/currency/coinbase.png";
import {
  METAMASK_CONNECTOR,
  WALLET_CONNECTOR,
  COINBASE_CONNECTOR,
} from "./constants";
import { ethers } from "ethers";

export const gamesList = [
  {
    link: "/mega-dice",
    return: 100,
    title: "Mega Dice",
    text: ["Choose Over or Under, roll", <br />, "dice and claim your prize"],
    image: megaDice,
  },
  {
    link: "/coin-flip",
    return: 1000,
    title: "Coin Flip",
    text: ["Guess Heads or Tails", <br />, "to double your bet"],
    image: coinFlip,
  },
  {
    link: "/limbo",
    return: 100,
    title: "Limbo",
    text: ["Predict the multiplier ", <br />, "and win"],
    image: limbo,
  },
  {
    link: "/plinko",
    return: "coming soon",
    title: "Plinko",
    text: ["Drop the ball and", <br />, "watch it bounce"],
    image: plinko,
  },
];

export const barabanSlicesList = [
  "3",
  "5",
  "1",
  "0.2",
  "0.1",
  "100",
  "0.1",
  "0.2",
  "1",
  "2",
  "3",
  "5",
  "1",
  "0.2",
  "0.1",
  "1 000",
  "0.1",
  "0.2",
  "1",
  "2",
];

export const walletsList = [
  {
    connector: "injected",
    icon: metamask,
    title: "Metamask",
    soon: false,
    isMagic: false
  },
  {
    connector: "walletConnect",
    icon: walletConnect,
    title: "Wallet Connect",
    soon: false,
    isMagic: false
  },
  {
    connector: "coinbaseWallet",
    icon: coinbase,
    title: "Coinbase",
    soon: false,
    isMagic: false
  },
  {
    connector: "magicWallet",
    icon: coinbase,
    title: "Magic",
    soon: false,
    isMagic: true
  },
];

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const toHexStr = (str) => {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
};

export function prettyValue(n, decimals) {
  n = n instanceof ethers.BigNumber ? n : ethers.BigNumber.from(n)
  let decimal = ethers.BigNumber.from(1).mul(ethers.BigNumber.from(10).pow(decimals))
  return n.lt(decimal)
    ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(5))
    : n.lt(decimal.mul(10))
      ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(4))
      : n.lt(decimal.mul(100))
        ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(3))
        : n.lt(decimal.mul(1000))
          ? Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(2))
          : Number(Number(ethers.utils.formatUnits(n, decimals)).toFixed(1));
}

export function strip(number) {
  return parseFloat(number.toPrecision(12));
}

export function getWallet(connector) {
  if (connector?.connector?.name === 'Metamask') return METAMASK_CONNECTOR;
  if (connector?.connector?.name === 'WalletConnectV2') return WALLET_CONNECTOR;
  if (connector?.connector?.name === 'CoinbaseWallet') return COINBASE_CONNECTOR;
  return "Unknown";
}