import { useEffect, useRef, useState } from "react";
import { CLOSE_ICON } from "../../assets/utilis/icons";
// import { CHECK_ICON } from "../../assets/utilis/icons";
// import { networksList } from "../../assets/utilis/utilis";
import { walletsList } from "../../assets/utilis/utilis";

import "./WalletModal.css";

function WalletModal({
  isOpen,
  onClose,
  activateBrowserWallet,
}) {
  const overlay = useRef();
  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);
      // console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = "unset";
      // console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  // const selectNetwork = (chainId) => {
  //   window.localStorage.setItem("network", chainId);
  //   setNetwork(chainId);
  // };

  const selectProvider = (type, isMagic) => {
    activateBrowserWallet(type, isMagic);
    onClose();
    // console.log("provider: ", type);
  };

  return (
    <div className={`wallet-modal ${isOpen ? "wallet-modal_opened" : ""}`}>
      <div className="wallet-modal__container">
        <button
          className="wallet-modal__close-btn"
          type="button"
          aria-label="Close connect wallet popup"
          onClick={onClose}
        >
          {CLOSE_ICON}
        </button>

        <div className="wallet-modal__connect-block">
          {/* <div className="wallet-modal__select-box">
            <p className="wallet-modal__select-title">Select Network</p>
            <ul className="wallet-modal__options-list">
              {networksList.map((coin, i) => (
                <li className="wallet-modal__option-item" key={`network${i}`}>
                  <button
                    className={`wallet-modal__option-btn ${
                      coin.soon ? "wallet-modal__option-btn_disabled" : ""
                    } ${
                      Number(network) === Number(coin.chainId) &&
                      isSupportedNetwork
                        ? "wallet-modal__option-btn_selected"
                        : ""
                    }`}
                    type="button"
                    aria-label={`Select ${coin.chainName} network`}
                  >
                    {Number(network) === Number(coin.chainId) &&
                    isSupportedNetwork ? (
                      <div className="wallet-modal__selected-icon">
                        {CHECK_ICON}
                      </div>
                    ) : null}

                    {coin.soon ? (
                      <div className="wallet-modal__soon-box">
                        <p className="wallet-modal__soon-text">Soon</p>
                      </div>
                    ) : null}

                    <img
                      className="wallet-modal__option-icon"
                      src={coin.icon}
                      alt={`${coin.chainName} icon`}
                    />
                    <p className="wallet-modal__option-title">{coin.title}</p>
                  </button>
                </li>
              ))}
            </ul>
          </div> */}

          <div className="wallet-modal__select-box">
            <p className="wallet-modal__select-title">Select Wallet</p>
            <ul className="wallet-modal__options-list">
              {walletsList.map((wallet, i) => (
                <li className="wallet-modal__option-item" key={`wallet${i}`}>
                  <button
                    className={`wallet-modal__option-btn wallet-modal__option-btn_type_wallet ${wallet.soon ? "wallet-modal__option-btn_disabled" : ""
                      }`}
                    type="button"
                    aria-label={`Select ${wallet.title} wallet`}
                    onClick={() => selectProvider(wallet.connector, wallet.isMagic)}
                  >
                    {wallet.soon ? (
                      <div className="wallet-modal__soon-box">
                        <p className="wallet-modal__soon-text">Soon</p>
                      </div>
                    ) : null}
                    <img
                      className="wallet-modal__option-icon"
                      src={wallet.icon}
                      alt={`${wallet.title} icon`}
                    />
                    <p className="wallet-modal__option-title">{wallet.title}</p>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* <div className="wallet-modal__info-block">
          <p className="wallet-modal__info-title">
            Haven’t got a wallet or&nbsp;needed network?
          </p>
          <div className="wallet-modal__learn-btn">
            <p className="wallet-modal__learn-btn-text">
              Learn about crypto wallets & networks
            </p>
          </div>
        </div> */}
      </div>

      <div
        className="wallet-modal__overlay"
        ref={overlay}
        onTouchStart={onClose}
      />
    </div>
  );
}

export default WalletModal;
